const ded = [
    `<h2>Your payment was <span class="green-text">successful</span>!</h2>`,
    `You have now`,
    `<span class="green-text">credits</span>`,
    `One credit equals one image`,
    `Have fun`,
    `CREATE NEW IMAGE`
];

export default ded
