<template>
  <div
    class="settings_filters_popup-option-poses_item"
    :key="item.id"
    :class="{'settings_filters_popup-option-poses_item--active': item.id === activeItem}"
  >
    <div class="settings_filters_popup-option-poses_item-description-image">
      <img
        :src="item.img"
        alt="Body type of girl"
        draggable="false"
      >
    </div>
    <span class="settings_filters_popup-option-poses_item-description">
      {{ item.title }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'PopupCardPoses',
    props: {
      item: {},
      activeItem: {}
    },
    setup(props) {
      return {

      }
    }
  }
</script>

<style lang="scss">
.settings_filters_popup-option-poses_item {
  &-description {
    display: block;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    text-wrap: nowrap;
    width: 133px;

    &-image {
      width: 133px;
      height: 135px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 10px;
      border: 2px solid;
      border-color: transparent;
      margin-bottom: 10px;

      img {
        min-height: 100%;
        width: 100%;
      }

      @media screen and (max-width: 768px) {
        width: 263px;
        height: 293px;
        flex-shrink: 0;

        img {
          height: 100%;
          min-height: initial;
          width: initial;
        }
      }

      @media screen and (max-width: 428px) {
        width: 260px;
        height: 260px;
      }
    }

    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }


  &--active {
    .settings_filters_popup-option-poses_item-description-image {
      border-color: #ACF601;
    }
  }
}
</style>
