<template>
  <div class="p-terms">
    <Container class="p-terms_container">
      <div v-html="pageLangData[`successful_payment_${locale}`][0]"></div>

      <div class="bottom-block">
        <p>
          <span v-html="pageLangData[`successful_payment_${locale}`][1]"></span>
          <!-- Amount of credits -->
          {{ getMyBalance }}
          <span v-html="pageLangData[`successful_payment_${locale}`][2]"></span>
        </p>

        <p>
          <span v-html="pageLangData[`successful_payment_${locale}`][3]"></span>
        </p>

        <p>
          <span v-html="pageLangData[`successful_payment_${locale}`][4]"></span>
        </p>

        <img src="@/assets/img/smiley.png" alt="smiley" >

        <router-link class="button" to="generate-girl">{{ pageLangData[`successful_payment_${locale}`][5] }}</router-link>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from "../components/layout-positions/Container";

import Cookies from "js-cookie";
import { useI18n } from "vue-i18n";
import axios from "axios";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
// import successful_payment_ae from '@/htmlLangsPages/successful_payment_us_no.js'
// import successful_payment_de from '@/htmlLangsPages/successful_payment_us_de.js'
import successful_payment_en from '@/htmlLangsPages/successful_payment_en.js'
// import successful_payment_fr from '@/htmlLangsPages/successful_payment_us_fr.js'
// import successful_payment_it from '@/htmlLangsPages/successful_payment_us_it.js'
// import successful_payment_ja from '@/htmlLangsPages/successful_payment_us_ja.js'
// import successful_payment_pl from '@/htmlLangsPages/successful_payment_us_pl.js'
// import successful_payment_pt from '@/htmlLangsPages/successful_payment_us_pt.js'
// import successful_payment_ru from '@/htmlLangsPages/successful_payment_us_ru.js'
// import successful_payment_sp from '@/htmlLangsPages/successful_payment_us_sp.js'
// import successful_payment_sd from '@/htmlLangsPages/successful_payment_us_sw.js'
// import successful_payment_tr from '@/htmlLangsPages/successful_payment_us_tr.js'
// import successful_payment_ua from '@/htmlLangsPages/successful_payment_us_ua.js'
// import successful_payment_no from '@/htmlLangsPages/successful_payment_us_no.js'

export default {
  name: "ConfirmEmail",
  components: {
    Container
  },
  setup () {
    const { locale } = useI18n()
    const store = useStore();

    const pageLangData = {
      successful_payment_en,
    }

    // Logic for getting the amount of credits
    const getMyBalance = computed(() => {
      return store.getters.getMyBalance;
    });

    onMounted(async () => {
      const subid = Cookies.get("subid");

      if (subid) {
        try {
          await axios.get("https://ktr.dressdown.ai/6e4c40f/postback", {
            params: {
              subid,
              status: "sale",
              payout: "amount",
            },
          });
        } catch (error) {
          console.error("Error sending postback:", error);
        }
      }
    });

    return {
      pageLangData,
      locale,
      getMyBalance,
    }
  }
}
</script>

<style lang="scss" scoped>
.p-terms {
  margin-bottom: 20px;

  &_container {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 50px;
    width: calc(100% - 20px);
    max-width: 1132px;
    border-radius: 20px;
    background: #2C2B2B;

    @media screen and (max-width: 1152px) {
      margin: 0 10px;
    }
  }

  :deep {
    .green-text {
      color: #ACF601;
    }

    h2 {
      color: #FFF;
      text-align: center;
      font-size: 64px;
      font-weight: 600;
      margin-bottom: 40px;

      @media screen and (max-width: 768px) {
        font-size: 36px;
      }
    }

    p {
      font-size: 40px;
      font-weight: 600;

      @media screen and (max-width: 768px) {
        font-size: 32px;
        text-align: center;
      }
    }

    .bottom-block {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 665px;
      margin: 0 auto;

      img {
        width: 102px;
        height: 103px;
      }
    }

    a.button {
      margin-top: 20px;
      display: flex;
      width: 100%;
      max-width: 563px;
      padding: 18px;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      background: #ACF601;
      color: #000;
      text-align: center;
      font-size: 36px;
      font-weight: 600;
      text-transform: capitalize;
      text-decoration: none;
    }
  }
}
</style>
