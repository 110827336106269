<template>
  <div class="p-terms">
    <Container class="p-terms_container">
      <div v-html="pageLangData[`confirm_email_${locale}`][0]"></div>

      <p class="open-email-text">
        <img src="@/assets/img/email.png" alt="email" style="width: 46px; height: 46px;"/>
        <span v-html="pageLangData[`confirm_email_${locale}`][1]"></span>
      </p>

      <div class="bottom-block">
        <img src="@/assets/img/email-example.png" alt="email-example" >

        <p class="info-text">
          <span v-html="pageLangData[`confirm_email_${locale}`][2]"></span>
        </p>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from "../components/layout-positions/Container";
import { useI18n } from "vue-i18n";
// import confirm_email_ae from '@/htmlLangsPages/confirm_email_us_no.js'
// import confirm_email_de from '@/htmlLangsPages/confirm_email_us_de.js'
import confirm_email_en from '@/htmlLangsPages/confirm_email_en.js'
// import confirm_email_fr from '@/htmlLangsPages/confirm_email_us_fr.js'
// import confirm_email_it from '@/htmlLangsPages/confirm_email_us_it.js'
// import confirm_email_ja from '@/htmlLangsPages/confirm_email_us_ja.js'
// import confirm_email_pl from '@/htmlLangsPages/confirm_email_us_pl.js'
// import confirm_email_pt from '@/htmlLangsPages/confirm_email_us_pt.js'
// import confirm_email_ru from '@/htmlLangsPages/confirm_email_us_ru.js'
// import confirm_email_sp from '@/htmlLangsPages/confirm_email_us_sp.js'
// import confirm_email_sd from '@/htmlLangsPages/confirm_email_us_sw.js'
// import confirm_email_tr from '@/htmlLangsPages/confirm_email_us_tr.js'
// import confirm_email_ua from '@/htmlLangsPages/confirm_email_us_ua.js'
// import confirm_email_no from '@/htmlLangsPages/confirm_email_us_no.js'

export default {
  name: "ConfirmEmail",
  components: {
    Container
  },
  setup () {
    const { locale } = useI18n()
    const pageLangData = {
      confirm_email_en,
    }

    return {
      pageLangData,
      locale
    }
  }
}
</script>

<style lang="scss" scoped>
.p-terms {
  &_container {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 50px;
    width: calc(100% - 20px);
    max-width: 1132px;
    border-radius: 20px;
    background: #2C2B2B;

    @media screen and (max-width: 1152px) {
      margin: 0 10px;
    }
  }

  :deep {
    .green-text {
      color: #ACF601;
    }

    h5, h3 {
      margin-bottom: 10px;
      font-size: 28px;
      font-weight: 600;
    }

    h2 {
      color: #FFF;
      text-align: center;
      font-size: 64px;
      font-weight: 600;

      @media screen and (max-width: 768px) {
        font-size: 36px;
      }
    }

    p {
      font-size: 18px;
      line-height: 1.5;
    }

    h6 {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: 600;
    }

    li {
      margin: 10px 0;
    }
    ul {
      padding-left: 15px;
    }

    .bottom-block {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 665px;
      margin: 0 auto;

      img {
        vertical-align: middle;
        margin-right: 10px;
        width: 100%;
      }
    }

    .open-email-text {
      color: #FFF;
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      vertical-align: middle;

      @media screen and (max-width: 768px) {
        font-size: 24px;
      }

      img {
        vertical-align: middle;
        margin-right: 10px;
        width: 100%;
      }
    }

    .info-text {
      color: #FFF;
      text-align: center;
      font-size: 24px;
      font-weight: 600;

      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
  }
}
</style>
