<template>
  <div class="p-terms">
    <Container class="p-terms_container">
      <h1>404 ERROR</h1>
      <h2>Page Not Found</h2>

      <router-link to='/'>
        <img src="@/assets/icons/404.svg" alt="cancel" />
      </router-link>
    </Container>
  </div>
</template>

<script>
import Container from "../components/layout-positions/Container";

export default {
  name: "PageNotFound",
  components: {
    Container
  },
}
</script>

<style lang="scss" scoped>
.p-terms {
  &_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    padding-bottom: 50px;
  }

  h1 {
    font-size: 128px;
    font-weight: 700;
    color: #FFFFFF;

    @media screen and (max-width: 768px) {
      font-size: 64px;
    }
  }

  h2 {
    font-size: 64px;
    font-weight: 500;
    color: #FFFFFF;

    @media screen and (max-width: 768px) {
      font-size: 36px;
    }
  }

  img {
    width: 100%;
    max-width: 443px;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
      max-width: 281px;
    }
  }
}
</style>
