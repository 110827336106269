<template>
  <ModalWrapper :model-name="modelName">
    <div class="settings_filters_popup">
      <div class="settings_filters_popup-holder">
        <img @click="closeFilter" class="settings_filters_popup-holder_head_icon" src="../../assets/icons/close.svg"
            alt="close close-filters" />

        <div v-if="activeStep === 1" class="settings_filters_popup--step">
          <h1 class="settings_filters_popup-holder_head_title"><span class="green-text">Create</span> your Images</h1>
          <div class="settings_filters_popup--step-number-holder">
            <p class="settings_filters_popup--step-number">
              <img src="../../assets/icons/step-active.svg" alt="step-active">
              <span>Let's Get Started</span>
            </p>
            <p class="settings_filters_popup--step-number">
              <img src="../../assets/icons/step-half.svg" alt="step-active">
              <span>Select a format</span>
            </p>
            <p class="settings_filters_popup--step-number">
              <img src="../../assets/icons/step-full.svg" alt="step-active">
              <span>Finish</span>
            </p>
          </div>
          <h4 class="settings_filters_popup-title">Select <span class="green-text">Type</span> of images</h4>
          <div class="settings_filters_popup-option">
            <div class="settings_filters_popup-option-wrapper option-wrapper-main">
              <PopupCard @click="activeGenerationType = item.id" :active-item="activeGenerationType"
                v-for="item of [undressType, posesType]" :key="item.id + 'categories'" :item="item" />
            </div>
          </div>
          <div class="settings_filters_popup--action">
            <button @click="nextStep" class="settings_filters_popup--action_button">
              {{ $t("buttons.nextStep") }}
            </button>
          </div>
        </div>

        <template v-if="activeGenerationType === 1">
          <div v-if="activeStep === 2" class="settings_filters_popup--step">
            <div v-show="activeGenerationType === 1">
              <!--   Type for undressing in different suits     -->
              <h4 class="settings_filters_popup-holder_head_title">Choose the image <span class="green-text">format</span> you need</h4>
              <div class="settings_filters_popup--step-number-holder">
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-active.svg" alt="step-active">
                  <span>Let's Get Started</span>
                </p>
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-half.svg" alt="step-active">
                  <span>Select a format</span>
                </p>
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-full.svg" alt="step-active">
                  <span>Finish</span>
                </p>
              </div>
              <div class="settings_filters_popup-option">
                <div class="settings_filters_popup-pagination show-mobile">
                  <div
                    class="settings_filters_popup-pagination-arrow settings_filters_popup-pagination_left swiper-button-prev">
                  </div>
                  <div
                    class="settings_filters_popup-pagination-arrow settings_filters_popup-pagination_right swiper-button-next">
                  </div>
                </div>
                <div class="settings_filters_popup-option-wrapper option-wrapper-undress">
                  <Swiper
                    :modules="modules"
                    :navigation="{
                      nextEl: '.settings_filters_popup-pagination_right',
                      prevEl: '.settings_filters_popup-pagination_left'
                    }"
                    :pagination="{ el: '.swiper-pagination', clickable: true }"
                    slides-per-view="auto"
                    :slidesPerGroup="1"
                    :spaceBetween="40"

                    :breakpoints="{
                      768: {
                        centeredSlides: false
                      }
                    }"

                    :centeredSlides="true"
                    style="padding: 25px 0; cursor: grab;"
                    class="show-mobile"
                  >
                    <SwiperSlide v-for="(item, index) in undressLists" :key="item.id + 'categoriesUndress'"
                      style="width: auto;" @click="activeUndressListType = item.id">
                      <PopupCard :active-item="activeUndressListType" :item="item" />
                    </SwiperSlide>
                  </Swiper>
                  <PopupCard
                    v-for="(item, index) in undressLists"
                    :key="item.id + 'categoriesUndress'"
                    style="width: auto;"
                    @click="activeUndressListType = item.id"
                    :active-item="activeUndressListType"
                    :item="item"
                    class="hide-mobile"
                  />
                </div>
              </div>
              <div class="settings_filters_popup--action">
                <button @click="prevStep" class="settings_filters_popup--action-left-button">
                  {{ $t("buttons.prevStep") }}
                </button>
                <button @click="nextStep" class="settings_filters_popup--action-right-button">
                  {{ $t('buttons.nextStep') }}
                </button>
              </div>
            </div>
          </div>

          <template v-if="activeUndressListType === 1">
            <div v-if="activeStep === 3" class="settings_filters_popup--step">
              <h4 class="settings_filters_popup-holder_head_title">Find Your Ideal <span class="green-text">Size</span> Here </h4>
              <div class="settings_filters_popup--step-number-holder">
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-active.svg" alt="step-active">
                  <span>Let's Get Started</span>
                </p>
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-active.svg" alt="step-active">
                  <span>Select a format</span>
                </p>
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-full.svg" alt="step-active">
                  <span>Finish</span>
                </p>
              </div>
              <div class="settings_filters_popup-option">
                <div v-show="activeUndressListType === 1" class="settings_filters_popup-pagination">
                  <div
                    class="settings_filters_popup-pagination-arrow settings_filters_popup-ActiveTits_left swiper-button-prev">
                  </div>
                  <div
                    class="settings_filters_popup-pagination-arrow settings_filters_popup-ActiveTits_right swiper-button-next">
                  </div>
                </div>
                <div class="settings_filters_popup-option-wrapper">
                  <!--   Body types for undressing     -->
                  <div v-show="activeUndressListType === 1" class="settings_filters_popup-option">
                    <div class="settings_filters_popup-option-wrapper">
                      <Swiper
                        v-show="activeUndressListType === 1"
                        :modules="modules"
                        :navigation="{
                          nextEl: '.settings_filters_popup-ActiveTits_right',
                          prevEl: '.settings_filters_popup-ActiveTits_left'
                        }"
                        :pagination="{ el: '.swiper-pagination', clickable: true }"
                        slides-per-view="auto"
                        :spaceBetween="40"

                        :breakpoints="{
                          768: {
                            centeredSlides: false
                          }
                        }"
                        :centeredSlides="true"
                        style="padding: 25px 0; cursor: grab;"
                      >
                        <SwiperSlide
                          @click="activeTits = item.id" v-for="item of breastArr"
                          :key="item.id + 'tits'"
                          style="width: auto;"
                        >
                          <PopupCard :active-item="activeTits" :item="item" />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>
                  <!--   FINISH types for undressing     -->
                </div>
              </div>
              <div class="settings_filters_popup--action">
                <button @click="prevStep" class="settings_filters_popup--action-left-button">
                  {{ $t("buttons.prevStep") }}
                </button>
                <button @click="nextStep" class="settings_filters_popup--action-right-button">
                  {{ $t("buttons.nextStep") }}
                </button>
              </div>
            </div>

            <div v-if="activeStep === 4" class="settings_filters_popup--step">
              <h4 class="settings_filters_popup-holder_head_title">Choose a format of <span class="green-text">Ass</span></h4>
              <div class="settings_filters_popup--step-number-holder">
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-active.svg" alt="step-active">
                  <span>Let's Get Started</span>
                </p>
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-active.svg" alt="step-active">
                  <span>Select a format</span>
                </p>
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-full.svg" alt="step-active">
                  <span>Finish</span>
                </p>
              </div>
              <div class="settings_filters_popup-option">
                <div v-show="activeUndressListType === 1" class="settings_filters_popup-pagination">
                  <div
                    class="settings_filters_popup-pagination-arrow settings_filters_popup-ActiveBack_left swiper-button-prev">
                  </div>
                  <div
                    class="settings_filters_popup-pagination-arrow settings_filters_popup-ActiveBack_right swiper-button-next">
                  </div>
                </div>
                <div class="settings_filters_popup-option-wrapper">
                  <!--   Body types for undressing     -->
                  <div v-show="activeUndressListType === 1" class="settings_filters_popup-option">
                    <div class="settings_filters_popup-option-wrapper">
                      <Swiper
                        v-show="activeUndressListType === 1"
                        :modules="modules"
                        :navigation="{
                          nextEl: '.settings_filters_popup-ActiveBack_right',
                          prevEl: '.settings_filters_popup-ActiveBack_left'
                        }"
                        :pagination="{ el: '.swiper-pagination', clickable: true }"
                        slides-per-view="auto"
                        :spaceBetween="40"

                        :breakpoints="{
                          768: {
                            centeredSlides: false
                          }
                        }"
                        :centeredSlides="true"
                        style="padding: 25px 0; cursor: grab;"
                      >
                        <SwiperSlide @click="activeBack = item.id" v-for="item of backTypes"
                        :key="item.id + 'back'" style="width: auto;">
                          <PopupCard :active-item="activeBack" :item="item" />
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>
                  <!--   FINISH types for undressing     -->
                </div>
              </div>
              <div class="settings_filters_popup--action">
                <button @click="prevStep" class="settings_filters_popup--action-left-button">
                  {{ $t("buttons.prevStep") }}
                </button>
                <button @click="nextStep" class="settings_filters_popup--action-right-button">
                  {{ $t("buttons.nextStep") }}
                </button>
              </div>
            </div>

            <div v-if="activeStep === 5" class="settings_filters_popup--step">
              <h4 class="settings_filters_popup-holder_head_title">Choose your <span class="green-text">body</span> type format</h4>
              <div class="settings_filters_popup--step-number-holder">
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-active.svg" alt="step-active">
                  <span>Let's Get Started</span>
                </p>
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-active.svg" alt="step-active">
                  <span>Select a format</span>
                </p>
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-active.svg" alt="step-active">
                  <span>Finish</span>
                </p>
              </div>
              <div class="settings_filters_popup-option">
                <div v-show="activeUndressListType === 1" class="settings_filters_popup-pagination">
                  <div
                    class="settings_filters_popup-pagination-arrow settings_filters_popup-BodyType_left swiper-button-prev">
                  </div>
                  <div
                    class="settings_filters_popup-pagination-arrow settings_filters_popup-BodyType_right swiper-button-next">
                  </div>
                </div>
                <div class="settings_filters_popup-option-wrapper">
                  <!--   Body types for undressing     -->
                  <Swiper
                    v-show="activeUndressListType === 1"
                    :modules="modules"
                    :navigation="{
                      nextEl: '.settings_filters_popup-BodyType_right',
                      prevEl: '.settings_filters_popup-BodyType_left'
                    }"
                    :pagination="{ el: '.swiper-pagination', clickable: true }"
                    slides-per-view="auto"
                    :spaceBetween="40"

                    :breakpoints="{
                      768: {
                        centeredSlides: false
                      }
                    }"

                    :centeredSlides="true"
                    style="padding: 25px 0; cursor: grab;"
                  >
                    <SwiperSlide v-for="(item, index) in bodyTypes" :key="item.id + 'categoriesUndress'"
                      @click="activeBody = item.id" style="width: auto;">
                      <PopupCard :active-item="activeBody" :item="item" />
                    </SwiperSlide>
                  </Swiper>
                  <!--   FINISH types for undressing     -->
                </div>
              </div>
              <div class="settings_filters_popup--action">
                <button @click="prevStep" class="settings_filters_popup--action-left-button">
                  {{ $t("buttons.prevStep") }}
                </button>
                <button @click="handleGenerate" class="settings_filters_popup--action-right-button">
                  {{ $t('edit.generate') }}
                </button>
              </div>
            </div>
          </template>

          <template v-else>
            <div v-if="activeStep === 3" class="settings_filters_popup--step">
              <h4 class="settings_filters_popup-holder_head_title">Choose a type of <span class="green-text">{{ currentTypeTitle }}</span></h4>
              <div class="settings_filters_popup--step-number-holder">
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-active.svg" alt="step-active">
                  <span>Let's Get Started</span>
                </p>
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-active.svg" alt="step-active">
                  <span>Select a format</span>
                </p>
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-active.svg" alt="step-active">
                  <span>Finish</span>
                </p>
              </div>
              <div class="settings_filters_popup-option">
                <div class="settings_filters_popup-option-wrapper option-wrapper-undress">
                  <!--   Type for underwear in colored     -->
                  <div v-show="activeUndressListType === 2" class="settings_filters_popup-pagination">
                    <div
                      class="settings_filters_popup-pagination-arrow settings_filters_popup-underwear_left swiper-button-prev">
                    </div>
                    <div
                      class="settings_filters_popup-pagination-arrow settings_filters_popup-underwear_right swiper-button-next">
                    </div>
                  </div>
                  <Swiper
                    v-show="activeUndressListType === 2"
                    :modules="modules"
                    :navigation="{
                      nextEl: '.settings_filters_popup-underwear_right',
                      prevEl: '.settings_filters_popup-underwear_left'
                    }"
                    :pagination="{ el: '.swiper-pagination', clickable: true }"
                    style="padding: 25px 0; cursor: grab;"
                    slides-per-view="auto"
                    :spaceBetween="40"

                    :breakpoints="{
                      768: {
                        centeredSlides: false
                      }
                    }"
                    :centeredSlides="true"
                  >
                    <SwiperSlide v-for="(item, index) in ifSelectedUnderwearType" :key="item.id + 'underwear'"
                      @click="ifSelectedUnderwearTypeActive = item.id" style="width: auto;">
                      <PopupCard :active-item="ifSelectedUnderwearTypeActive" :item="item" />
                    </SwiperSlide>
                  </Swiper>
                  <!--   Type for garters in colored     -->
                  <div v-show="activeUndressListType === 3" class="settings_filters_popup-pagination">
                    <div
                      class="settings_filters_popup-pagination-arrow settings_filters_popup-garters_left swiper-button-prev">
                    </div>
                    <div
                      class="settings_filters_popup-pagination-arrow settings_filters_popup-garters_right swiper-button-next">
                    </div>
                  </div>
                  <Swiper
                    v-show="activeUndressListType === 3"
                    :modules="modules"
                    :navigation="{
                      nextEl: '.settings_filters_popup-garters_right',
                      prevEl: '.settings_filters_popup-garters_left'
                    }"
                    :pagination="{ el: '.swiper-pagination', clickable: true }"
                    style="padding: 25px 0; cursor: grab;"
                    slides-per-view="auto"
                    :spaceBetween="40"

                    :breakpoints="{
                      768: {
                        centeredSlides: false
                      }
                    }"
                    :centeredSlides="true"
                  >
                    <SwiperSlide v-for="(item, index) in ifSelectedGartersType" :key="item.id + 'garters'"
                      style="width: auto;" @click="ifSelectedGartersTypeActive = item.id">
                      <PopupCard :active-item="ifSelectedGartersTypeActive" :item="item" />
                    </SwiperSlide>
                  </Swiper>
                  <!--   Type for bikini in colored     -->
                  <div v-show="activeUndressListType === 4" class="settings_filters_popup-pagination">
                    <div
                      class="settings_filters_popup-pagination-arrow settings_filters_popup-bikini_left swiper-button-prev">
                    </div>
                    <div
                      class="settings_filters_popup-pagination-arrow settings_filters_popup-bikini_right swiper-button-next">
                    </div>
                  </div>
                  <Swiper
                    v-show="activeUndressListType === 4"
                    :modules="modules"
                    :navigation="{
                      nextEl: '.settings_filters_popup-bikini_right',
                      prevEl: '.settings_filters_popup-bikini_left'
                    }"
                    :pagination="{ el: '.swiper-pagination', clickable: true }"
                    space-between="0"
                    style="padding: 25px 0; cursor: grab;"
                    slides-per-view="auto"
                    :spaceBetween="40"

                    :breakpoints="{
                      768: {
                        centeredSlides: false
                      }
                    }"
                    :centeredSlides="true"
                  >
                    <SwiperSlide v-for="(item, index) in ifSelectedBikiniType" :key="item.id + 'bikini'"
                      style="width: auto;" @click="ifSelectedBikiniTypeActive = item.id">
                      <PopupCard :active-item="ifSelectedBikiniTypeActive" :item="item" />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              <div class="settings_filters_popup--action">
                <button @click="prevStep" class="settings_filters_popup--action-left-button">
                  {{ $t("buttons.prevStep") }}
                </button>
                <button @click="handleGenerate" class="settings_filters_popup--action-right-button">
                  {{ $t('edit.generate') }}
                </button>
              </div>
            </div>

            <div v-if="activeStep === 4" class="settings_filters_popup--step">
              <div class="settings_filters_popup--step-number-holder">
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-active.svg" alt="step-active">
                  <span>Let's Get Started</span>
                </p>
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-half.svg" alt="step-active">
                  <span>Select a format</span>
                </p>
                <p class="settings_filters_popup--step-number">
                  <img src="../../assets/icons/step-full.svg" alt="step-active">
                  <span>Finish</span>
                </p>
              </div>
              <h4 class="settings_filters_popup-title">Select <span class="green-text">type</span> of images</h4>
              <div class="settings_filters_popup-option">
                <div class="settings_filters_popup-option-wrapper">
                  <!--    STATIC Category AGE    -->
                  <div v-show="activeUndressListType === 1"
                    class="settings_filters_popup-option settings_filters_popup-option-age">
                    <h4 class="settings_filters_popup-title">Age</h4>
                    <div class="settings_filters_popup-option-wrapper-age">
                      <div class="settings_filters_popup-option_item-age" v-for="item of ages" :key="item.id"
                        @click="activeAge = item.id"
                        :class="{ 'settings_filters_popup-option_item--active': item.id === activeAge }">
                        {{ item.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="settings_filters_popup--action">
                <button @click="prevStep" class="settings_filters_popup--action-left-button">
                  {{ $t("buttons.prevStep") }}
                </button>
                <button @click="nextStep" class="settings_filters_popup--action-right-button">
                  {{ $t("buttons.nextStep") }}
                </button>
              </div>
            </div>
          </template>
        </template>

        <template v-if="activeGenerationType === 2">
          <div v-if="activeStep === 2" class="settings_filters_popup--step">
            <h1 class="settings_filters_popup-holder_head_title">Choose a type of <span class="green-text">Sex Poses</span></h1>
            <div class="settings_filters_popup--step-number-holder show-mobile">
              <p class="settings_filters_popup--step-number">
                <img src="../../assets/icons/step-active.svg" alt="step-active">
                <span>Let's Get Started</span>
              </p>
              <p class="settings_filters_popup--step-number">
                <img src="../../assets/icons/step-active.svg" alt="step-active">
                <span>Select a format</span>
              </p>
              <p class="settings_filters_popup--step-number">
                <img src="../../assets/icons/step-full.svg" alt="step-active">
                <span>Finish</span>
              </p>
            </div>
            <div class="swiper-block" v-show="activeGenerationType === 2">
              <div
                class="settings_filters_popup-pagination-arrow settings_filters_popup-pagination_left settings_filters_popup-suit_left swiper-button-prev show-mobile">
              </div>
              <div
                class="settings_filters_popup-pagination-arrow settings_filters_popup-pagination_right  settings_filters_popup-suit_right swiper-button-next show-mobile">
              </div>
              <div class="settings_filters_popup-option-wrapper option-wrapper-poses">
                <Swiper
                  v-show="currentPosesChild.child.length >= 1"
                  :modules="modules"
                  :navigation="{
                    nextEl: '.settings_filters_popup-suit_right',
                    prevEl: '.settings_filters_popup-suit_left'
                  }"
                  :pagination="{ el: '.swiper-pagination', clickable: true }"
                  slides-per-view="auto"
                  :spaceBetween="40"

                  :breakpoints="{
                    768: {
                      centeredSlides: false
                    }
                  }"
                  :centeredSlides="true"
                  style="padding: 10px 0; cursor: grab;"
                  class="show-mobile"
                >
                  <SwiperSlide v-for="(item, index) in posesList" :key="item.id + 'poses'" style="width: auto;"
                    @click="selectedPoses = item.id">
                    <PopupCardPoses :active-item="selectedPoses" :item="item" />
                  </SwiperSlide>
                </Swiper>
                <PopupCardPoses class="hide-mobile" v-for="(item, index) in posesList" :key="item.id + 'poses'"
                  style="height: 172px;" :active-item="selectedPoses" :item="item" @click="selectedPoses = item.id" />
              </div>
            </div>

            <div class="settings_filters_popup--action">
              <!-- <button @click="nextStep" class="settings_filters_popup_button">
                {{ $t("buttons.nextStep") }}
              </button> -->
              <button @click="prevStep" class="settings_filters_popup--action-left-button">
                {{ $t("buttons.prevStep") }}
              </button>
              <button @click="nextStep" class="settings_filters_popup--action-right-button">
                {{ $t('buttons.nextStep') }}
              </button>
            </div>
          </div>

          <div v-if="activeStep === 3" class="settings_filters_popup--step">
            <h1 class="settings_filters_popup-holder_head_title">Select a format of <span class="green-text">{{ currentPosesTitle }}</span></h1>
            <div class="settings_filters_popup--step-number-holder">
              <p class="settings_filters_popup--step-number">
                <img src="../../assets/icons/step-active.svg" alt="step-active">
                <span>Let's Get Started</span>
              </p>
              <p class="settings_filters_popup--step-number">
                <img src="../../assets/icons/step-active.svg" alt="step-active">
                <span>Select a format</span>
              </p>
              <p class="settings_filters_popup--step-number">
                <img src="../../assets/icons/step-active.svg" alt="step-active">
                <span>Finish</span>
              </p>
            </div>
            <h4 class="settings_filters_popup-title">Select <span class="green-text">type</span> of images</h4>
            <div class="settings_filters_popup-option">
              <div v-show="currentPosesChild.child.length >= 1" class="settings_filters_popup-pagination">
                <div
                  class="settings_filters_popup-pagination-arrow settings_filters_popup-suit_left swiper-button-prev">
                </div>
                <div
                  class="settings_filters_popup-pagination-arrow settings_filters_popup-suit_right swiper-button-next">
                </div>
              </div>
              <div class="settings_filters_popup-option-wrapper">
                <Swiper
                  v-show="currentPosesChild.child.length >= 1"
                  :modules="modules"
                  :navigation="{
                    nextEl: '.settings_filters_popup-suit_right',
                    prevEl: '.settings_filters_popup-suit_left'
                  }"
                  :pagination="{ el: '.swiper-pagination', clickable: true }"
                  slides-per-view="auto"
                  :spaceBetween="40"

                  :breakpoints="{
                    768: {
                      centeredSlides: false
                    }
                  }"
                  :centeredSlides="true"
                  style="padding: 10px 0; cursor: grab;"
                >
                  <SwiperSlide
                    v-for="(item, index) in currentPosesChild.child"
                    :key="item.id + currentPosesChild.keygen"
                    style="width: auto;"
                    @click.prevent="selectedOutfits = item.id"
                  >
                    <PopupCard class="settings_filters_popup-option_item-poses-card" :active-item="selectedOutfits"
                      :item="item" />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div class="settings_filters_popup--action">
              <button @click="prevStep" class="settings_filters_popup--action-left-button">
                {{ $t("buttons.prevStep") }}
              </button>
              <button @click="handleGenerate" class="settings_filters_popup--action-right-button">
                {{ $t('edit.generate') }}
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import PopupCard from "@/components/filter/PopupCard.vue";
import PopupCardPoses from "@/components/filter/PopupCardPoses.vue";
import ModalWrapper from "@/components/modals/ModalWrapper.vue";
import undressTypeImg from '@/assets/filter-type-image/undress.png'
import posesTypeImg from '@/assets/anal/woman.webp'
import { useI18n } from "vue-i18n";
// Just Swiper
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import { EffectFade, Pagination, Navigation } from "swiper";
import 'swiper/swiper-bundle.css';
// filter-type-for-undress
import bikiniImg from '@/assets/filters-bikini/black.webp'
import gartersImg from '@/assets/latex/black.webp'
import underwearImg from '@/assets/filter-underwear/black.webp'
// If selected undress type
import selectedUndressMiddleTits from '@/assets/filter-girls-images/middle.png'
import selectedUndressNormalBody from '@/assets/filter-girls-images/normal-body.png'
import selectedUndressMediumBack from '@/assets/filter-girls-images/medium-dupa.png'
// If selected underwear type
import blackImg from '@/assets/filter-underwear/black.webp'
import pinkImg from '@/assets/filter-underwear/pink.webp'
import greenImg from '@/assets/filter-underwear/green.webp'
import blueImg from '@/assets/filter-underwear/blue.webp'
import purpleImg from '@/assets/filter-underwear/purple.webp'
import orangeImg from '@/assets/filter-underwear/orange.webp'
// If selected garters LATEX type
import gartersBlackImg from '@/assets/latex/black.webp'
import gartersBlueImg from '@/assets/latex/blue.webp'
import gartersGreenImg from '@/assets/latex/green.webp'
import gartersPinkImg from '@/assets/latex/pink.webp'
import gartersOrangeImg from '@/assets/latex/orange.webp'
import gartersPurpleImg from '@/assets/latex/purple.webp'
// If selected Bikini type
import bikiniBlack from "@/assets/filters-bikini/black.webp"
import bikiniColored from "@/assets/filters-bikini/rainbow.webp"
import bikiniGreen from "@/assets/filters-bikini/green.webp"
import bikiniOrange from "@/assets/filters-bikini/orange.webp"
import bikiniPink from "@/assets/filters-bikini/pink.webp"
import bikiniPurple from "@/assets/filters-bikini/purple.webp"
// Poses
import cumshotImg from "@/assets/poses/cumshot.png"
import bbcImg from "@/assets/poses/bbcblowjob.png"
import cumInMImg from "@/assets/poses/cuminmouth.png"
import cumFaceImg from "@/assets/poses/cumface.png"
import dickCumImg from "@/assets/poses/dickcum.png"
import ahegaoImg from "@/assets/poses/ahegao.png"
import breastImg from "@/assets/poses/breastclamp.png"
import missionaryImg from "@/assets/poses/mission.png"
import gaggedImg from "@/assets/poses/gagged.png"
import analImg from "@/assets/poses/anal.png"
// Cumshot
import cumshotNone from "@/assets/cumshot/woman.webp"
import cumshotNun from "@/assets/cumshot/nun.webp"
import cumshotTeacher from "@/assets/cumshot/teacher.webp"
import cumshotStudent from "@/assets/cumshot/student.webp"
import cumshotNurse from "@/assets/cumshot/nurse.webp"
// BBC
import nunBBCImg from "@/assets/bbc/nun.webp"
import schoolBBCImg from "@/assets/bbc/student.webp"
import nurseBBCImg from "@/assets/bbc/nurse.webp"
import teacherBBCImg from "@/assets/bbc/teacher.webp"
import noneBBCImg from "@/assets/bbc/woman.webp"
// Cum in mouth
import nunMouth from "@/assets/cumMouth/nun.webp"
import nurseMouth from "@/assets/cumMouth/nurse.webp"
import studentMouth from "@/assets/cumMouth/student.webp"
import noneMouth from "@/assets/cumMouth/woman.webp"
import teacherMouth from "@/assets/cumMouth/teacher.webp"
// Face Cum
import faceCumNun from "@/assets/faceCum/nun.webp"
import faceCumNurse from "@/assets/faceCum/nurse.webp"
import faceCumSchool from "@/assets/faceCum/student.webp"
import faceCumTeacher from "@/assets/faceCum/teacher.webp"
import faceCumWoman from "@/assets/faceCum/woman.webp"
// Dick Cum
import dickCumNun from "@/assets/dickCum/nun.webp"
import dickCumNurse from "@/assets/dickCum/nurse.webp"
import dickCumStudent from "@/assets/dickCum/student.webp"
import dickCumTeacher from "@/assets/dickCum/teacher.webp"
import dickCumNone from "@/assets/dickCum/woman.webp"
// Ahegao Japan
import ahegaoNun from "@/assets/ahegao/nun.webp"
import ahegaoNurse from "@/assets/ahegao/nurse.webp"
import ahegaoStudent from "@/assets/ahegao/student.webp"
import ahegaoTeacher from "@/assets/ahegao/teacher.webp"
import ahegaoNone from "@/assets/ahegao/woman.webp"
// Breast Clamp
import breastNun from "@/assets/breast/nun.webp"
import breastNurse from "@/assets/breast/nurse.webp"
import breastStudent from "@/assets/breast/student.webp"
import breastTeacher from "@/assets/breast/teacher.webp"
import breastNone from "@/assets/breast/woman.webp"
// Anal
import analNone from "@/assets/anal/woman.webp"
import analNun from "@/assets/anal/nun.webp"
import analTeacher from "@/assets/anal/teacher.webp"
import analStudent from "@/assets/anal/student.webp"
import analNurse from "@/assets/anal/nurse.webp"

// Mission
import missionNone from "@/assets/mission/woman.webp"
import missionNun from "@/assets/mission/nun.webp"
import missionTeacher from "@/assets/mission/teacher.webp"
import missionStudent from "@/assets/mission/student.webp"
import missionNurse from "@/assets/mission/nurse.webp"

// Gagged
import gaggedNun from "@/assets/gagged/nun.webp"
import gaggedNurse from "@/assets/gagged/nurse.webp"
import gaggedTeacher from "@/assets/gagged/teacher.webp"
import gaggedStudent from "@/assets/gagged/student.webp"
import gaggedNone from "@/assets/gagged/woman.webp"
// Titsjob
import titsjobNun from "@/assets/titsBlowjob/nun.webp"
import titsjobNurse from "@/assets/titsBlowjob/nurse.webp"
import titsjobTeacher from "@/assets/titsBlowjob/teacher.webp"
import titsjobStudent from "@/assets/titsBlowjob/student.webp"
import titsjobNone from "@/assets/titsBlowjob/woman.webp"
// Male Blowjob
import maleBlowJob from "@/assets/maleBlowjob/manBlowjob.webp"

export default {
  name: "SettingsFilterPopup",
  components: {
    Swiper,
    SwiperSlide,
    PopupCard,
    PopupCardPoses,
    ModalWrapper,
  },
  props: {
    isOpenFilters: Boolean,
    breastArr: {},
    backTypes: {},
    bodyTypes: {},
    ages: {},
    modelName: {
      type: String,
      default: "SettingsFilterPopup"
    }
  },
  setup(_, { emit }) {
    const store = useStore()
    const { t } = useI18n()

    const activeTits = ref(1)
    const activeBack = ref(1)
    const activeBody = ref(1)
    const activeAge = ref(1)

    const activeStep = ref(1)
    const currentActiveType = ref(1)

    const activeGenerationType = ref(1)

    const undressType = {
      id: 1,
      img: undressTypeImg,
      title: t('filters.undress'),
      premium: false
    }

    const posesType = {
      id: 2,
      img: posesTypeImg,
      title: t('filters.poses'),
      premium: true,
    }
    // Undress Categories
    const activeUndressListType = ref(1)
    const undressLists = [
      { id: 1, title: t('filters.undressBlock.undress'), img: undressTypeImg, premium: true },
      { id: 2, title: t('filters.undressBlock.underwear'), img: underwearImg, premium: false },
      { id: 3, title: t('filters.undressBlock.garters'), img: gartersImg, premium: false },
      { id: 4, title: t('filters.undressBlock.bikini'), img: bikiniImg, premium: false },
    ]
    // UndressType
    const ifSelectedUndressTypeActiveType = ref(1)
    const ifSelectedUndressType = [
      { id: 1, title: t('filters.selectedUndress.breast'), img: selectedUndressMiddleTits },
      { id: 2, title: t('filters.selectedUndress.butt'), img: selectedUndressMediumBack },
      { id: 3, title: t('filters.selectedUndress.body'), img: selectedUndressNormalBody },
    ]
    // UNDERWEAR
    const ifSelectedUnderwearTypeActive = ref(1)
    const ifSelectedUnderwearType = [
      { id: 1, title: t('filters.selectedGarters.black'), img: blackImg, value: 'black' },
      { id: 2, title: t('filters.bikini.pink'), img: pinkImg, value: 'pink' },
      { id: 3, title: t('filters.bikini.blue'), img: blueImg, value: 'blue' },
      { id: 4, title: t('filters.bikini.green'), img: greenImg, value: 'green' },
      { id: 5, title: t('filters.bikini.orange'), img: orangeImg, value: 'orange' },
      { id: 6, title: t('filters.bikini.purple'), img: purpleImg, value: 'purple' },
    ]
    // GARTERS
    const ifSelectedGartersTypeActive = ref(1)
    const ifSelectedGartersType = [
      { id: 1, title: t('filters.selectedGarters.black'), img: gartersBlackImg, value: 'black' },
      { id: 2, title: t('filters.bikini.blue'), img: gartersBlueImg, value: 'pink' },
      { id: 3, title: t('filters.bikini.green'), img: gartersGreenImg, value: 'green' },
      { id: 4, title: t('filters.bikini.orange'), img: gartersOrangeImg, value: 'orange' },
      { id: 5, title: t('filters.bikini.pink'), img: gartersPinkImg, value: 'pink' },
      { id: 6, title: t('filters.bikini.purple'), img: gartersPurpleImg, value: 'purple' },
    ]
    // BIKINI
    const ifSelectedBikiniTypeActive = ref(1)
    const ifSelectedBikiniType = [
      { id: 1, title: t('filters.bikini.black'), img: bikiniBlack, value: 'black' },
      { id: 2, title: t('filters.bikini.colored'), img: bikiniColored, value: 'rainbow' },
      { id: 3, title: t('filters.bikini.green'), img: bikiniGreen, value: 'green' },
      { id: 4, title: t('filters.bikini.pink'), img: bikiniPink, value: 'pink' },
      { id: 5, title: t('filters.bikini.orange'), img: bikiniOrange, value: 'orange' },
      // { id: 6, title: t('filters.bikini.purple'), img: bikiniPurple, value: 'purple' },
    ]

    // POSES

    // Cumshot on tits
    const cumshotTypes = [
      { id: 1, title: t('filters.ahegao.none'), img: cumshotNone, value: 'none' },
      { id: 2, title: t('filters.ahegao.nun'), img: cumshotNun, value: 'nun' },
      { id: 4, title: t('filters.ahegao.school'), img: cumshotStudent, value: 'student' },
      { id: 3, title: t('filters.ahegao.teacher'), img: cumshotTeacher, value: 'teacher' },
      { id: 5, title: t('filters.ahegao.nurse'), img: cumshotNurse, value: 'nurse' },
    ]
    // BBC
    const bbcTypes = [
      { id: 1, title: t('filters.ahegao.none'), img: noneBBCImg, value: 'none' },
      { id: 2, title: t('filters.ahegao.nun'), img: nunBBCImg, value: 'nun' },
      { id: 3, title: t('filters.ahegao.student'), img: schoolBBCImg, value: 'student' },
      { id: 4, title: t('filters.ahegao.teacher'), img: teacherBBCImg, value: 'teacher' },
      { id: 5, title: t('filters.ahegao.nurse'), img: nurseBBCImg, value: 'nurse' },
    ]
    // Cum in mouth
    const cumMouthTypes = [
      { id: 1, title: t('filters.ahegao.none'), img: noneMouth, value: 'none' },
      { id: 2, title: t('filters.ahegao.nun'), img: nunMouth, value: 'nun' },
      { id: 3, title: t('filters.ahegao.student'), img: studentMouth, value: 'student' },
      { id: 4, title: t('filters.ahegao.teacher'), img: teacherMouth, value: 'teacher' },
      { id: 5, title: t('filters.ahegao.nurse'), img: nurseMouth, value: 'nurse' },
    ]
    // Cum Face
    const faceCumTypes = [
      { id: 1, title: t('filters.ahegao.none'), img: faceCumWoman, value: 'none' },
      { id: 2, title: t('filters.ahegao.nun'), img: faceCumNun, value: 'nun' },
      { id: 3, title: t('filters.ahegao.school'), img: faceCumSchool, value: 'student' },
      { id: 4, title: t('filters.ahegao.teacher'), img: faceCumTeacher, value: 'teacher' },
      { id: 5, title: t('filters.ahegao.nurse'), img: faceCumNurse, value: 'nurse' },
    ]
    // Dick Cum
    const dickCumTypes = [
      { id: 1, title: t('filters.ahegao.none'), img: dickCumNone, value: 'none' },
      { id: 2, title: t('filters.ahegao.nun'), img: dickCumNun, value: 'nun' },
      { id: 3, title: t('filters.ahegao.student'), img: dickCumStudent, value: 'student' },
      { id: 4, title: t('filters.ahegao.teacher'), img: dickCumTeacher, value: 'teacher' },
      { id: 5, title: t('filters.ahegao.nurse'), img: dickCumNurse, value: 'nurse' },
    ]
    // Ahegao
    const ahegaoTypes = [
      { id: 1, title: t('filters.ahegao.none'), img: ahegaoNone, value: 'none' },
      { id: 2, title: t('filters.ahegao.nun'), img: ahegaoNun, value: 'nun' },
      { id: 3, title: t('filters.ahegao.school'), img: ahegaoStudent, value: 'student' },
      { id: 4, title: t('filters.ahegao.teacher'), img: ahegaoTeacher, value: 'teacher' },
      { id: 5, title: t('filters.ahegao.nurse'), img: ahegaoNurse, value: 'nurse' },
    ]
    // Breast Clamp
    const breastTypes = [
      { id: 1, title: t('filters.ahegao.none'), img: breastNone, value: 'none' },
      { id: 2, title: t('filters.ahegao.nun'), img: breastNun, value: 'nun' },
      { id: 3, title: t('filters.ahegao.student'), img: breastStudent, value: 'student' },
      { id: 4, title: t('filters.ahegao.teacher'), img: breastTeacher, value: 'teacher' },
      { id: 5, title: t('filters.ahegao.nurse'), img: breastNurse, value: 'nurse' },
    ]
    // Gagged
    const gaggedTypes = [
      { id: 1, title: t('filters.ahegao.none'), img: gaggedNone, value: 'none' },
      { id: 2, title: t('filters.ahegao.nun'), img: gaggedNun, value: 'nun' },
      { id: 3, title: t('filters.ahegao.school'), img: gaggedStudent, value: 'student' },
      { id: 4, title: t('filters.ahegao.teacher'), img: gaggedTeacher, value: 'teacher' },
      { id: 5, title: t('filters.ahegao.nurse'), img: gaggedNurse, value: 'nurse' },
    ]
    // Anal
    const analTypes = [
      { id: 1, title: t('filters.ahegao.none'), img: analNone, value: 'none' },
      { id: 2, title: t('filters.ahegao.nun'), img: analNun, value: 'nun' },
      { id: 3, title: t('filters.ahegao.school'), img: analStudent, value: 'student' },
      { id: 4, title: t('filters.ahegao.teacher'), img: analTeacher, value: 'teacher' },
      { id: 5, title: t('filters.ahegao.nurse'), img: analNurse, value: 'nurse' },
    ]
    // Mission
    const misionTypes = [
      { id: 1, title: t('filters.ahegao.none'), img: missionNone, value: 'none' },
      { id: 2, title: t('filters.ahegao.nun'), img: missionNun, value: 'nun' },
      { id: 3, title: t('filters.ahegao.school'), img: missionStudent, value: 'student' },
      { id: 4, title: t('filters.ahegao.teacher'), img: missionTeacher, value: 'teacher' },
      { id: 5, title: t('filters.ahegao.nurse'), img: missionNurse, value: 'nurse' },
    ]
    // Mission
    const titsBlowjobTypes = [
      { id: 1, title: t('filters.ahegao.none'), img: titsjobNone, value: 'none' },
      { id: 2, title: t('filters.ahegao.nun'), img: titsjobNun, value: 'nun' },
      { id: 3, title: t('filters.ahegao.school'), img: titsjobStudent, value: 'student' },
      { id: 4, title: t('filters.ahegao.teacher'), img: titsjobTeacher, value: 'teacher' },
      { id: 5, title: t('filters.ahegao.nurse'), img: titsjobNurse, value: 'nurse' },
    ]
    // Male Blowjob
    const maleBlowjobTypes = [
      { id: 1, title: t('filters.posesTypes.maleBlowjob'), img: maleBlowJob, value: 'none' },
    ]

    // POSES
    const categories = [
      "",
      "boobs in cum",
      "titsjob",
      "blowjob",
      "cum in mouth",
      "cum on face",
      "cum on face",
      "ahegao",
      "breast clamp",
      "missioniere",
      "ball gag",
      "analride",
      "maleblowjob"
    ];
    const selectedOutfits = ref(1)
    const selectedPoses = ref(1)
    const posesList = [
      { id: 1, title: t('filters.posesTypes.cumshot'), img: cumshotNone, child: cumshotTypes, keygen: 'cumshot' },
      { id: 2, title: t('filters.posesTypes.titsJob'), img: titsjobNone, child: titsBlowjobTypes, keygen: 'titsjob' },
      { id: 3, title: t('filters.posesTypes.bbcblowjob'), img: noneBBCImg, child: bbcTypes, keygen: 'bbc' },
      { id: 4, title: t('filters.posesTypes.cuminmouth'), img: noneMouth, child: cumMouthTypes, keygen: 'cuminmouth' },
      { id: 5, title: t('filters.posesTypes.cumface'), img: faceCumWoman, child: faceCumTypes, keygen: 'cumface' },
      { id: 6, title: t('filters.posesTypes.dickcum'), img: dickCumNone, child: dickCumTypes, keygen: 'dickcum' },
      { id: 7, title: t('filters.posesTypes.ahegao'), img: ahegaoNone, child: ahegaoTypes, keygen: 'ahegao' },
      { id: 8, title: t('filters.posesTypes.breastclamp'), img: breastNone, child: breastTypes, keygen: 'breast' },
      { id: 9, title: t('filters.posesTypes.mission'), img: missionNone, child: misionTypes, keygen: 'mission' },
      { id: 10, title: t('filters.posesTypes.gagged'), img: gaggedNone, child: gaggedTypes, keygen: 'gagged' },
      { id: 11, title: t('filters.posesTypes.anal'), img: analNone, child: analTypes, keygen: 'anal' },
      { id: 12, title: t('filters.posesTypes.maleBlowjob'), img: maleBlowJob, child: maleBlowjobTypes, keygen: 'maleblowjob' }
    ]
    // Get Child array's data from poses
    const currentPosesChild = computed(() => {
      const result = posesList.find(item => item.id === selectedPoses.value)
      if (result) {
        return {
          child: result.child,
          keygen: result.keygen
        }
      }

      return {
        child: [],
        keygen: 'notFound'
      }
    })
    const currentPosesTitle = computed(() => {
      const result = posesList.find(item => item.id === selectedPoses.value)
      if (result) {
        return result.title
      }

      return ''
    })

    const currentTypeTitle = computed(() => {
      const result = undressLists.find(item => item.id === activeUndressListType.value)
      if (result) {
        return result.title
      }

      return ''
    })

    const childData = computed(() => currentPosesChild.value.child);
    // Prevent default child from poses Array
    watch(childData, (newValue, oldValue) => {
      selectedOutfits.value = 1
    });


    const closeFilter = () => {
      activeStep.value = 1
      store.commit('removeAllModal')
      emit('closeModal')
    }
    const handleGenerate = () => {
      if (activeGenerationType.value === 1) {
        undressGeneration()
      }
      if (activeGenerationType.value === 2) {
        posesGeneration()
      }

      activeStep.value = 1

      store.commit('removeAllModal')
      emit('closeModal')
    }
    const posesGeneration = () => {
      emit('handleGenerate', {
        generation_type: categories[selectedPoses.value],
        character_type: currentPosesChild.value.child.find(item => item.id === selectedOutfits.value).value,
        type: 'poses'
      });
    }
    const undressGeneration = () => {
      switch (activeUndressListType.value) {
        case 1: {
          emit('handleGenerate', {
            activeTits: activeTits.value,
            activeBack: activeBack.value,
            activeBody: activeBody.value,
            activeAge: activeAge.value,
            type: 'undress'
          });
        }
          break
        case 2: {
          emit('handleGenerate', {
            generation_type: 'underwear',
            character_type: ifSelectedUnderwearType.find(item => item.id === ifSelectedUnderwearTypeActive.value).value,
            type: 'inpaint'
          });
        }
          break
        case 3: {
          emit('handleGenerate', {
            generation_type: 'latex',
            character_type: ifSelectedGartersType.find(item => item.id === ifSelectedGartersTypeActive.value).value,
            type: 'inpaint'
          });
        }
          break
        case 4: {
          emit('handleGenerate', {
            generation_type: 'bikini',
            character_type: ifSelectedBikiniType.find(item => item.id === ifSelectedBikiniTypeActive.value).value,
            type: 'inpaint'
          });
        }
          break
        default:
          return;
      }
    }
    const filtersPreventDefault = () => {
      activeTits.value = 1
      activeBack.value = 1
      activeBody.value = 1
      activeAge.value = 1
    }

    const nextStep = () => {
      activeStep.value += 1;
    }

    const prevStep = () => {
      if (activeStep.value > 1) {
        activeStep.value -= 1;
      }
    }

    return {
      filtersPreventDefault,
      handleGenerate,
      closeFilter,
      selectedOutfits,
      currentPosesChild,
      currentPosesTitle,
      currentTypeTitle,
      posesList,
      selectedPoses,
      ifSelectedUndressTypeActiveType,
      ifSelectedUndressType,
      ifSelectedGartersType,
      ifSelectedGartersTypeActive,
      ifSelectedBikiniType,
      ifSelectedBikiniTypeActive,
      ifSelectedUnderwearType,
      ifSelectedUnderwearTypeActive,
      activeGenerationType,
      activeUndressListType,
      undressLists,
      activeStep,
      currentActiveType,
      activeTits,
      activeBack,
      activeBody,
      activeAge,
      undressType,
      posesType,
      childData,
      nextStep,
      prevStep,
      modules: [EffectFade, Pagination, Navigation],
    };
  }
}
</script>

<style lang="scss" scoped>
.settings_filters_popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(22, 22, 22);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  margin: 0 auto;
  padding: 20px 30px;
  border-radius: 15px;
  width: 900px;
  overflow: hidden;

  .show-mobile {
    display: none;
  }

  &-title {
    color: #FFF;
    text-align: center;
    font-size: 40px;
    text-transform: capitalize;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }

  &-holder {
    overflow: auto;
    height: 100%;

    &_head {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;

      &_title {
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 20px;

        @media screen and (max-width: 768px) {
          font-size: 30px;
          margin-bottom: 10px;
        }
      }

      &_icon {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }

  &-body {
    position: relative;

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      max-height: 700px;
    }
  }

  &-buttons {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 15px;
    position: absolute;
    gap: 15px;
    top: 10px;
    left: 20px;

    button {
      width: 30px;
      height: 30px;
      border: none;
      background-color: transparent;
    }
  }

  &--step {
    height: 100%;
    padding-bottom: 70px;

    &-number {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 75px;
      gap: 8px;
      text-align: center;

      img {
        width: 75px;
        height: 75px;
      }

      &-holder {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 15px;
        position: relative;
        gap: 64px;

        @media screen and (max-width: 768px) {
          gap: 40px;
          margin-bottom: 10px;
        }
      }

      @media screen and (max-width: 428px) {
        width: 45px;
      }
    }
  }

  &--action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    padding: 0 40px;

    &_button {
      color: #000;
      text-align: center;
      font-size: 36px;
      font-weight: 600;
      border: none;
      position: relative;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0 auto;
      max-width: 563px;
      border-radius: 20px;
      background: #ACF601;
    }

    &-left-button {
      color: #000;
      text-align: center;
      font-size: 36px;
      font-weight: 600;
      border: none;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 30px;
      background: #ACF601;

      @media screen and (max-width: 480px) {
        margin-left: 10px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-color: transparent;
        border-style: solid;
        border-width: 40px 40px 40px 0;
        right: 100%;
        border-right-color: #ACF601 !important;

        @media screen and (max-width: 768px) {
          border-width: 20px 20px 20px 0;
        }
      }
    }

    &-right-button {
      color: #000;
      text-align: center;
      font-size: 36px;
      font-weight: 600;
      border: none;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 30px;
      background: #ACF601;

      @media screen and (max-width: 480px) {
        margin-right: 10px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-color: transparent;
        border-style: solid;
        border-width: 40px 0 40px 40px;
        left: 100%;
        border-left-color: #ACF601 !important;

        @media screen and (max-width: 768px) {
          border-width: 20px 0 20px 20px;
        }
      }
    }

    button {
      @media screen and (max-width: 768px) {
        font-size: 18px;
        padding: 2px 4px;
      }
    }
  }

  &-option {
    margin-bottom: 20px;
    width: 100%;
    position: relative;

    :deep {
      &-wrapper:not(.option-wrapper-main) {
        gap: 10px;
        justify-content: space-between;

        .settings_filters_popup-option_item-description-image {
          width: 192px;
          height: 256px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: initial;
            height: 100%;
          }
        }
      }
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;

      &.option-wrapper-poses {
        justify-content: flex-start;
        margin-bottom: 10px;

        .settings_filters_popup-option-poses_item {
          width: 25%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        @media screen and (max-width: 768px) {
          // justify-content: flex-start;
          flex-wrap: nowrap;
          overflow-x: auto;

          .settings_filters_popup-option-poses_item {
            width: 100%;
            height: auto;
          }
        }
      }

      &.option-wrapper-main {
        justify-content: center;
        gap: 90px;

        @media screen and (max-width: 768px) {
          gap: 10px;

          :deep {
            .settings_filters_popup-option_item-description-image {
              width: 174px;
              height: 300px;
            }
          }
        }

        @media screen and (max-width: 428px) {
          :deep {
            .settings_filters_popup-option_item-description-image {
              width: 126px;
              height: 220px;
            }
          }
        }
      }

      &.option-wrapper-undress {
        justify-content: center;
        gap: 30px;

        @media screen and (max-width: 768px) {
          gap: 10px;

          :deep {
            .settings_filters_popup-option_item-description-image {
              img {
                width: 100%;
                height: initial;
                min-height: initial;
              }
            }
          }
        }
      }

      @media screen and (max-width: 768px) {
        align-items: flex-start;
      }
    }
  }

  .settings_filters_popup-pagination {
    width: 100%;

    &-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
      width: 43px;
      height: 43px;
      flex-shrink: 0;
      border-radius: 50%;
      background-color: #ACF601;
      color: #000;
      font-size: 25px !important;

      &:after {
        font-size: inherit;
      }
    }

    &_left {
      left: 0;
      right: initial;
    }

    &_right {
      left: initial;
      right: 0;
    }
  }

  .swiper-block {
    position: relative;
  }

  .green-text {
    color: #ACF601;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 25px 13px;

    .show-mobile {
      display: flex !important;
    }

    .hide-mobile {
      display: none !important;
    }
  }

}
</style>